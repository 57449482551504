import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../layout/Footer";
import Loader from "../../layout/Loader";
import Menu from "../../layout/Menu";
import Add from "./Add";
import Update from "./Update";
import { ADMIN_JOINED_FROM, COMPANY_SUBSCRIPTION_STATUS, formatDate } from "../../../constants";

const Company = () => {
  const [users, setUsers] = useState([]);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alert2, setAlert2] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [changed, setChanged] = useState(false);
  const [updateId, setUpdateId] = useState();
  const rows = [20, 50, 100];
  const [userPerPage, setUserPerPage] = useState(rows[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [managerModal, setManagerModal] = useState(false);
  const [managerModal1, setManagerModal1] = useState(false);

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    getUserData();
    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  const getUserData = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/admins-list";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((response) => {
      if (response.data.success === true) {
        let res = response.data.data;
        let arr = [];
        res.map((elem, index) => {
          arr.push({ ...elem, indexs: index + 1 });
        });
        setUsers(arr);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setDisplayUsers(users);
  }, [users]);

  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = displayUsers.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(displayUsers.length / userPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (num) => {
    setCurrentPage(num);
  };

  const handleDeleteUser = (id) => {
    setAlert(true);
    setDeleteId(id);
  };

  const DeleteUser = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/delete-admin";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("id", deleteId);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success == true) {
          setDeleteId("");
          setAlert(false);
          setAlert2(true);
        }
      })
      .catch((error) => {
        //console.log("Errors", error);
      });
  };

  const handleUserLogin = (id) => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/company-login";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("userId", id);
    bodyFormData.append("masterId", sessionStorage.getItem("TES_Admin_ID"));

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success == true) {
          window.open(
            process.env.REACT_APP_COMPANY_URL + response.data.data.token
          );
        }
      })
      .catch((error) => {
        // console.log("Errors", error);
        toast.error("Something went wrong");
      });
  };

  const handleCompanyUserLogin = (id) => {
    const myurl =
      `${process.env.REACT_APP_base_url}` +
      "api/masterAdmin/company-user-login";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("company", id);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success === true) {
          window.open(
            process.env.REACT_APP_COMPANY_USER_URL + response.data.data.token
          );
        }
      })
      .catch((error) => {
        // console.log("Errors", error);
        toast.error("Something went wrong");
      });
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      let search = e.target.value;
      setDisplayUsers(
        users.filter(
          (elem) =>
            elem.username.toLowerCase().includes(search.toLowerCase()) ||
            elem.email.toLowerCase().includes(search.toLowerCase())
        )
      );

      setCurrentPage(1);
    } else {
      setDisplayUsers(users);
    }
  };

  const handleFilterChange = (e) => {
    const search = e.target.value;

    if (search && search !== "ALL") {
      setDisplayUsers(
        users.filter((elem) =>
          elem.joinedFrom.toLowerCase().includes(search.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setDisplayUsers(users);
    }
  };

  useEffect(() => {
    if (changed) {
      getUserData();
    }
  }, [changed]);

  return (
    <Fragment>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <Add
          activeModal={managerModal}
          setActiveModal={() => setManagerModal(false)}
          setUpdateModal={() => setChanged(true)}
        />
        <Update
          activeModal={managerModal1}
          setActiveModal={() => setManagerModal1(false)}
          setUpdateModal={() => setChanged(true)}
          handler={managerModal1}
          data={updateId}
        />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Company</li>
          </ol>
          <h1 className="page-header">
            Company{" "}
            <div
              className="btn btn-success"
              onClick={() => {
                setChanged(false);
                setManagerModal(true);
              }}
            >
              <i className="fa fa-plus"></i>
            </div>
          </h1>
          <div className="card">
            <div className="card-body">
              <div
                class="form-outline mb-4"
                style={{ display: "flex", gap: "10px" }}
              >
                <select
                  className="form-select"
                  onChange={handleFilterChange}
                  defaultValue="ALL"
                  style={{
                    padding: "5px 10px",
                    border: "1px solid #d5dbe0",
                    borderRadius: "4px",
                  }}
                >
                  {Object.entries(ADMIN_JOINED_FROM).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
                <input
                  type="search"
                  class="form-control"
                  id="datatable-search-input"
                  placeholder="Search Company"
                  onChange={handleSearch}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table id="product-listing" className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Payment Mode</th>
                          <th>Auto Pay Cancelled</th>
                          <th>Trial Mode</th>
                          <th>Trial End Date</th>
                          <th>Subscription Status</th>
                          <th>Next Invoice Date</th>
                          <th
                            style={{
                              position: "relative",
                              textAlign: "center",
                              padding: "10px",
                            }}
                          >
                            User Count{" "}
                            <span
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                display: "inline-block",
                                borderRadius: "50%",
                                width: "16px",
                                height: "16px",
                                backgroundColor: "#ccc",
                                textAlign: "center",
                                fontSize: "12px",
                                lineHeight: "16px",
                                fontWeight: "bold",
                                position: "relative",
                              }}
                              title="Show Active Count / Paid Count"
                            >
                              i
                            </span>
                          </th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr className="text-center">
                            <td colSpan={8}>
                              <i
                                className="fa fa-spinner fa-spin"
                                style={{ fontSize: "20px" }}
                              ></i>{" "}
                              Loading...
                            </td>
                          </tr>
                        ) : currentUsers.length > 0 ? (
                          currentUsers.map((elem, index) => (
                            <tr key={index}>
                              <td>{elem.indexs}</td>
                              <td>{elem.username}</td>
                              <td>{elem.email}</td>
                              <td>
                                {elem.validPaymentMethodRequired === true
                                  ? "Automatic"
                                  : "Manual"}
                              </td>
                              <td>
                                {
                                  elem.validPaymentMethodRequired ? elem.cancelAtPeriodEnd === true ? "Yes" : "No" : "-"
                                }
                              </td>
                              <td>
                                {elem.trialModeEnabled === true ? "Yes" : "No"}
                              </td>
                              <td>
                                {
                                  formatDate(elem?.trialPeriodEndDate)
                                }
                              </td>
                              <td>
                                {
                                  (
                                    elem.validPaymentMethodRequired === true ? COMPANY_SUBSCRIPTION_STATUS[elem?.currentSubscription?.status] : "-"
                                  )?.toUpperCase()
                                }
                              </td>
                              <td>
                                {
                                  formatDate(elem?.nextInvoiceDate)
                                }
                              </td>
                              <td>
                                {elem?.activeUserCount} /{" "}
                                {elem?.paidUserCount || 0}
                              </td>
                              <td>
                                {elem.status === 1 ? (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "green" }}
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span
                                    className="status"
                                    style={{ backgroundColor: "red" }}
                                  >
                                    Deleted
                                  </span>
                                )}
                              </td>
                              <td>
                                {elem.status === 1 && (
                                  <>
                                    <i
                                      className="fa fa-globe"
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        marginRight: "12px",
                                      }}
                                      title="Company Panel"
                                      onClick={() => handleUserLogin(elem.id)}
                                    ></i>
                                    <i
                                      className="fa fa-user"
                                      style={{
                                        color: "gray",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        marginRight: "12px",
                                      }}
                                      title="User Panel"
                                      onClick={() =>
                                        handleCompanyUserLogin(elem.id)
                                      }
                                    ></i>
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                        marginRight: "12px",
                                      }}
                                      onClick={() => {
                                        setUpdateId(elem);
                                        setChanged(false);
                                        setManagerModal1(true);
                                      }}
                                    ></i>
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleDeleteUser(elem.id)}
                                    ></i>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={8}>No Record Found..</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="gt-pagination"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ul class="pagination">
                      {pageNumbers.map((number) => (
                        <li
                          class={
                            currentPage === number
                              ? "page-item active"
                              : "page-item"
                          }
                          aria-current="page"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <span
                            class="page-link"
                            onClick={() => paginate(number)}
                          >
                            {number}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="filter-pages"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="selection" style={{ marginBottom: "0" }}>
                        Users Per Page :
                      </label>
                      <select
                        className="selection"
                        style={{
                          outline: "0",
                          borderWidth: "0 0 1px",
                          borderColor: "black",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onChange={(e) => setUserPerPage(e.target.value)}
                      >
                        {rows.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {alert === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={DeleteUser}
            onCancel={() => {
              setAlert(false);
              setDeleteId("");
            }}
          >
            You will not be able to recover this Company!
          </SweetAlert>
        ) : (
          ""
        )}
        {alert2 === true ? (
          <SweetAlert
            success
            title="User Deleted Successfully!"
            onConfirm={() => {
              setAlert2(false);
              getUserData();
            }}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </Fragment>
  );
};

export default Company;
