import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import { DEFAULT_PRICING_PLAN } from "../../../constants";

export default function Add(props) {
  const defaultData = {
    username: "",
    email: "",
    validPaymentMethodRequired: false,
    trialModeEnabled: true,
    pricingPlan: {
      ...DEFAULT_PRICING_PLAN,
    },
  };

  const [data, setData] = useState(defaultData);

  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setData(defaultData);
    setError({});
    setDisable(false);
  }, [props]);

  function validate() {
    let error = {},
      isValid = true;
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.username.trim() === "") {
      isValid = false;
      error["username"] = "Enter Valid username.";
    }
    if (
      data.email.trim() === "" ||
      !data.email ||
      !regEmail.test(data.email.trim())
    ) {
      isValid = false;
      error["email"] = "Enter Valid Email.";
    }
    setError(error);
    return isValid;
  }

  async function submitHandler() {
    try {
      setDisable(true);

      if (!validate()) {
        setDisable(false);
        return;
      }

      const myurl2 = `${process.env.REACT_APP_base_url}api/masterAdmin/add-admin-stripe`;

      // Construct request body as JSON
      const requestBody = {
        auth_code: process.env.REACT_APP_AUTH_CODE,
        username: data.username.trim(),
        email: data.email.trim(),
        validPaymentMethodRequired: data.validPaymentMethodRequired,
        trialModeEnabled: data.trialModeEnabled,
        pricingPlan: { ...data.pricingPlan }, // Keep pricingPlan as a nested object
      };

      // Send request with JSON headers
      const response = await axios.post(myurl2, requestBody, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        props.setActiveModal();
        props.setUpdateModal();
        setData(defaultData);
        toast.success("Company Added Successfully.");
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong!");
    } finally {
      setDisable(false);
    }
  }

  return (
    <>
      <Modal
        show={props.activeModal}
        onHide={props.setActiveModal}
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <span
            className="modal-header-title"
            style={{ fontWeight: "600", fontSize: "1.1rem" }}
          >
            Add Admin
          </span>
          <div
            className="close"
            onClick={props.setActiveModal}
            style={{ cursor: "pointer" }}
          >
            <strong>X</strong>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form>
            <fieldset
              style={{
                backgroundColor: "#F9F9F9",
                padding: "0px 20px 5px 20px",
                border: "1px solid #d5dbe0",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <legend
                style={{
                  fontSize: "0.85rem",
                  width: "max-content",
                  fontWeight: "600",
                  padding: "0px 5px",
                }}
              >
                USER DETAILS
              </legend>
              <div>
                <label>User Name</label> <br />
                <div className="input-text">
                  <input
                    className="form-control w-100 text-black"
                    type="text"
                    value={data.username}
                    onChange={(e) => {
                      let tempErr = error;
                      delete tempErr.username;
                      setError(tempErr);
                      setData({ ...data, username: e.target.value });
                    }}
                    style={{
                      height: "max-content",
                      padding: "4px",
                    }}
                  />
                  <div className="text-danger">{error.username}</div>
                </div>
              </div>
              <div style={{ margin: "15px 0px" }}>
                <label>Email</label> <br />
                <div className="input-text">
                  <input
                    className="form-control w-100 text-black"
                    type="email"
                    value={data.email}
                    onChange={(e) => {
                      let tempErr = error;
                      delete tempErr.email;
                      setError(tempErr);
                      setData({ ...data, email: e.target.value });
                    }}
                    style={{
                      height: "max-content",
                      padding: "4px",
                    }}
                  />
                  <div className="text-danger">{error.email}</div>
                </div>
              </div>
            </fieldset>
            <fieldset
              style={{
                backgroundColor: "#F9F9F9",
                padding: "0px 20px 5px 20px",
                border: "1px solid #d5dbe0",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <legend
                style={{
                  fontSize: "0.85rem",
                  width: "max-content",
                  fontWeight: "600",
                  padding: "0px 5px",
                }}
              >
                SUBSCRIPTION SETTINGS
              </legend>
              <div className="m-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Enable Auto Pay: {"  "}
                  <input
                    type="checkbox"
                    checked={data.validPaymentMethodRequired}
                    onChange={(e) => {
                      setData({
                        ...data,
                        validPaymentMethodRequired: e.target.checked,
                      });
                    }}
                  />
                </label>
              </div>
              <div className="mt-3">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Trial Period:{" "}
                  <input
                    type="checkbox"
                    checked={data.trialModeEnabled}
                    onChange={(e) => {
                      setData({ ...data, trialModeEnabled: e.target.checked });
                    }}
                  />
                </label>
              </div>
            </fieldset>
            <fieldset
              style={{
                backgroundColor: "#F9F9F9",
                padding: "0px 20px 5px 20px",
                border: "1px solid #d5dbe0",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <legend
                style={{
                  fontSize: "0.85rem",
                  width: "max-content",
                  fontWeight: "600",
                  padding: "0px 5px",
                }}
              >
                PRICING
              </legend>
              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Base Charge (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.baseCharge}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          baseCharge: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Company User (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.companyUser}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          companyUser: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Read Write (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.readWrite}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          readWrite: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Read Only (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.readOnly}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          readOnly: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Topo User (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.topo}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          topo: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                  />
                </label>
              </div>
            </fieldset>
          </form>
          <div className="selectionBtn" style={{ marginTop: "25px" }}>
            <button
              type="button"
              onClick={props.setActiveModal}
              className="cancelBtn mr-0"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={submitHandler}
              className="submitBtn"
              disabled={disable}
            >
              {disable ? "Saving..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
