export const ADMIN_JOINED_FROM = {
  SUPER_ADMIN: "SuperAdmin",
  WEBSITE_SIGNUP: "Website",
  ALL: "All",
};

export const DEFAULT_PRICING_PLAN = {
  baseCharge: 75,
  companyUser: 35,
  readWrite: 35,
  topo: 35,
  readOnly: 10,
  currency: 'usd',
};

export const COMPANY_SUBSCRIPTION_STATUS = {
  pending: 'Pending',
  active: 'Active',
  canceled: 'Canceled',
  trialing: 'Trial',
  past_due: 'Past Due',
  unpaid: 'Unpaid',
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete Expired'
}

export const formatDate = (dateString) => {
  if (!dateString) return "-";

  const date = new Date(dateString);
  return isNaN(date.getTime())
    ? "-"
    : date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
}