import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { DEFAULT_PRICING_PLAN } from "../../../constants";

export default function Update(props) {
  const defaultUpdateData = {
    username: props.data?.username.trim(),
    email: props.data?.email.trim(),
    id: props.data?.id.trim(),
    validPaymentMethodRequired: props.data?.validPaymentMethodRequired,
    trialModeEnabled: props.data?.trialModeEnabled,
    pricingPlan: {
      ...DEFAULT_PRICING_PLAN,
      ...props.data?.pricingPlan,
    },
  };

  const [data, setData] = useState(defaultUpdateData);
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [pricingNote, setPricingNote] = useState(null);

  const currentSubscriptionStatus = props.data?.currentSubscription?.status;
  const ALLOWED_PRICING_UPDATE_STATUS = ["trialing", "active", "past_due"];

  const allowPriceUpdate =
    (!props.data?.trialModeEnabled && !props.data?.validPaymentMethodRequired) ||
    props.data?.trialModeEnabled ||
    (props.data?.validPaymentMethodRequired &&
      ALLOWED_PRICING_UPDATE_STATUS.includes(currentSubscriptionStatus));


  const getPricingUpdateNote = () => {
    if (!props.data?.trialModeEnabled && props.data?.validPaymentMethodRequired && !ALLOWED_PRICING_UPDATE_STATUS.includes(currentSubscriptionStatus)) {
      return "Pricing updates are disabled. Subscription must be active or in trial mode to update pricing.";
    }

    return "";
  };

  useEffect(() => {
    setData(defaultUpdateData);
    setPricingNote(getPricingUpdateNote());
  }, [props.data?.id, props.handler]);

  function validate() {
    let error = {},
      isValid = true;
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (data.username.trim() == "") {
      isValid = false;
      error["username"] = "Enter Valid username.";
    }
    if (
      data.email.trim() == "" ||
      !data.email ||
      !regEmail.test(data.email.trim())
    ) {
      isValid = false;
      error["email"] = "Enter Valid Email.";
    }
    setError(error);
    return isValid;
  }

  async function submitHandler() {
    setDisable(true);
    if (validate()) {
      try {
        const myurl2 = `${process.env.REACT_APP_base_url}api/masterAdmin/update-admin`;

        const requestData = {
          auth_code: process.env.REACT_APP_AUTH_CODE,
          newUsername: data.username.trim(),
          newEmail: data.email.trim(),
          username: props.data?.username.trim(),
          email: props.data?.email.trim(),
          id: data.id,
          validPaymentMethodRequired: data.validPaymentMethodRequired,
          trialModeEnabled: data.trialModeEnabled,
          pricingPlan: {
            baseCharge: data.pricingPlan.baseCharge,
            companyUser: data.pricingPlan.companyUser,
            readWrite: data.pricingPlan.readWrite,
            readOnly: data.pricingPlan.readOnly,
            topo: data.pricingPlan.topo,
          },
        };

        const response = await axios.post(myurl2, requestData);

        if (response.data.success) {
          props.setActiveModal();
          props.setUpdateModal();
          setDisable(false);
          toast.success("Company Updated Successfully.");
        } else {
          setDisable(false);
          toast.error(response.data.message);
        }
      } catch (err) {
        setDisable(false);
        toast.error(err.response?.data?.message || "Error updating company.");
      }
    } else {
      setDisable(false);
    }
  }

  return (
    <>
      <Modal
        show={props.activeModal}
        onHide={props.setActiveModal}
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <span
            className="modal-header-title"
            style={{ fontWeight: "600", fontSize: "1.1rem" }}
          >
            Edit Admin
          </span>
          <div
            className="close"
            onClick={props.setActiveModal}
            style={{ cursor: "pointer" }}
          >
            <strong>X</strong>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form>
            <fieldset
              style={{
                backgroundColor: "#F9F9F9",
                padding: "0px 20px 5px 20px",
                border: "1px solid #d5dbe0",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <legend
                style={{
                  fontSize: "0.85rem",
                  width: "max-content",
                  fontWeight: "600",
                  padding: "0px 5px",
                }}
              >
                USER DETAILS
              </legend>
              <div>
                <label>User Name</label> <br />
                <div className="input-text">
                  <input
                    className="form-control w-100 text-black"
                    type="text"
                    value={data.username}
                    onChange={(e) => {
                      let tempErr = error;
                      delete tempErr.username;
                      setError(tempErr);
                      setData({ ...data, username: e.target.value });
                    }}
                    style={{
                      height: "max-content",
                      padding: "4px",
                    }}
                  />
                  <div className="text-danger">{error.username}</div>
                </div>
              </div>
              <div style={{ margin: "15px 0px" }}>
                <label>Email</label> <br />
                <div className="input-text">
                  <input
                    className="form-control w-100 text-black"
                    type="email"
                    value={data.email}
                    onChange={(e) => {
                      let tempErr = error;
                      delete tempErr.email;
                      setError(tempErr);
                      setData({ ...data, email: e.target.value });
                    }}
                    style={{
                      height: "max-content",
                      padding: "4px",
                    }}
                  />
                  <div className="text-danger">{error.email}</div>
                </div>
              </div>
            </fieldset>
            <fieldset
              style={{
                backgroundColor: "#F9F9F9",
                padding: "0px 20px 5px 20px",
                border: "1px solid #d5dbe0",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <legend
                style={{
                  fontSize: "0.85rem",
                  width: "max-content",
                  fontWeight: "600",
                  padding: "0px 5px",
                }}
              >
                SUBSCRIPTION SETTINGS
              </legend>
              <div className="m-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Enable Auto Pay: {"  "}
                  <input
                    type="checkbox"
                    checked={data.validPaymentMethodRequired}
                    onChange={(e) => {
                      setData({
                        ...data,
                        validPaymentMethodRequired: e.target.checked,
                      });
                    }}
                  />
                </label>
              </div>
              <div className="mt-3">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Trial Period:{" "}
                  <input
                    type="checkbox"
                    checked={data.trialModeEnabled}
                    onChange={(e) => {
                      setData({ ...data, trialModeEnabled: e.target.checked });
                    }}
                  />
                </label>
              </div>
            </fieldset>
            <fieldset
              style={{
                backgroundColor: "#F9F9F9",
                padding: "0px 20px 5px 20px",
                border: "1px solid #d5dbe0",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
            >
              <legend
                style={{
                  fontSize: "0.85rem",
                  width: "max-content",
                  fontWeight: "600",
                  padding: "0px 5px",
                }}
              >
                PRICING
              </legend>
              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Base Charge (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.baseCharge}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          baseCharge: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                    disabled={!allowPriceUpdate}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Company User (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.companyUser}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          companyUser: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                    disabled={!allowPriceUpdate}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Read Write (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.readWrite}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          readWrite: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                    disabled={!allowPriceUpdate}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Read Only (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.readOnly}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          readOnly: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                    disabled={!allowPriceUpdate}
                  />
                </label>
              </div>

              <div className="mt-2">
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Topo User (USD):{" "}
                  <input
                    type="number"
                    min="1"
                    value={data.pricingPlan.topo}
                    onChange={(e) =>
                      setData({
                        ...data,
                        pricingPlan: {
                          ...data.pricingPlan,
                          topo: Math.max(1, Number(e.target.value)),
                        },
                      })
                    }
                    style={{
                      border: "1px solid #d5dbe0",
                      borderRadius: "4px",
                      padding: "3px 5px",
                    }}
                    disabled={!allowPriceUpdate}
                  />
                </label>
              </div>
            </fieldset>
          </form>
          <div
            className="mt-2 text-sm text-gray-600"
            style={{
              backgroundColor: "#FDF3D1",
              border: "1px solid #FCEFC0",
              color: "#806520",
              borderRadius: "4px",
              padding: "3px 8px",
              fontSize: "0.8rem",
            }}
          >
            <span style={{ fontWeight: "600" }}>Note: </span>
            {allowPriceUpdate
              ? " Changes to the pricing plan will be applied from the next billing cycle."
              : pricingNote}
          </div>
          <div className="selectionBtn" style={{ marginTop: "25px" }}>
            <button
              type="button"
              onClick={props.setActiveModal}
              className="cancelBtn mr-0"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={submitHandler}
              className="submitBtn"
              disabled={disable}
            >
              {disable ? "Saving..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
