import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { all } from "../Redux/actions/index";
function Menu() {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const Logout = () => {
    sessionStorage.removeItem("TES_Admin_ID");
    toast.success("Logout Successfully...!");
    navigate(`/`);
  };

  var dashboardClass =
    window.location.pathname.match(/^\/dashboard/) ||
    window.location.pathname.match(/^\/detailed-dashboard/)
      ? "active"
      : "";
  var companyClass = window.location.pathname.match(/^\/company/)
    ? "active"
    : "";

  var planClass = window.location.pathname.match(/^\/plan/) ? "active" : "";

  var usersClass = window.location.pathname.match(/^\/users$/) ? "active" : "";
  var usersLogClass = window.location.pathname.match(/^\/users-log/)
    ? "active"
    : "";

  const [profileInfo, setProfileInfo] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem("TES_Admin_ID") == null) {
      toast.error("Please login first...!");
      navigate(`/`);
    }
    getProfileData();
  }, []);

  const getProfileData = () => {
    const myurl =
      `${process.env.REACT_APP_base_url}` + "api/masterAdmin/get-profile";
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", process.env.REACT_APP_AUTH_CODE);
    bodyFormData.append("id", sessionStorage.getItem("TES_Admin_ID"));
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success === true) {
          setProfileInfo(response.data.data);
        }
      })
      .catch((error) => {
        //console.log("Errors", error);
      });
  };
  return (
    <>
      <div id="header" className="header navbar-default">
        <div className="navbar-header">
          <Link to="/dashboard" className="navbar-brand">
            <span className="navbar-logo"></span>{" "}
            <b className="mr-1">SpatialVision </b> Admin
          </Link>
          <button
            type="button"
            className="navbar-toggle"
            data-click="sidebar-toggled"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown navbar-user">
            <a className="dropdown-toggle" data-toggle="dropdown">
              <img
                src={
                  profileInfo.profile_pic
                    ? profileInfo.profile_pic
                    : "assets/img/default-user.png"
                }
                alt=""
              />
              <span className="d-none d-md-inline">{profileInfo.username}</span>{" "}
              <b className="caret"></b>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="/profile" className="dropdown-item">
                Edit Profile
              </Link>
              <a onClick={Logout} className="dropdown-item">
                Log Out
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div id="sidebar" className="sidebar">
        <div data-scrollbar="true" data-height="100%">
          <ul className="nav">
            <li className="nav-header">Navigation</li>

            <li className={dashboardClass} onClick={() => dispatcher(all())}>
              <Link to="/dashboard">
                <i className="fa fa-th-large"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={companyClass} onClick={() => dispatcher(all())}>
              <Link to="/company">
                {/* <i className="fa fa-users"></i> */}
                <i class="fa fa-sitemap"></i>
                <span>Company</span>
              </Link>
            </li>
            <li className={usersClass} onClick={() => dispatcher(all())}>
              <Link to="/users">
                {/* <i className="fa fa-users"></i> */}
                <i class="fa fa-user"></i>
                <span>Users</span>
              </Link>
            </li>
            <li className={usersLogClass} onClick={() => dispatcher(all())}>
              <Link to="/users-log">
                {/* <i className="fa fa-users"></i> */}
                <i class="fa fa-users"></i>
                <span>Users Log</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-bg"></div>
    </>
  );
}

export default Menu;
